.contact_page__wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-left: 11.5vw;
  margin-right: 11.5vw;
  margin-bottom: 117px;

  .contact_page__headline {
    font-family: 'TT Firs Neue';
    font-size: 56px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .contact_page__inner_wrapper {
    display: flex;

    .form__wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;

      .form__container {
        display: flex;
        flex-direction: column;
        flex: 1;

        label {
          text-align: left;
          font-size: 14px;
          font-family: 'Roboto';
          font-weight: 400;
          margin-bottom: 6px;
        }

        input {
          height: 48px;
          border-radius: 14px;
          border: none;
          background-color: $borderGrey;
          padding-left: 16px;
          margin-bottom: 22px;
          font-size: 16px;
        }

        textarea {
          border: none;
          border-radius: 14px;
          background-color: $borderGrey;
          padding-left: 16px;
          margin-bottom: 40px;
          padding-top: 15px;
          font-size: 16px;
        }

        input:focus {
          outline: none;
        }

        input::placeholder {
          font-size: 16px;
          color: $lightlightGrey;
        }

        textarea:focus {
          outline: none;
        }

        textarea {
          resize: none;
        }

        textarea::placeholder {
          font-family: 'Roboto';
          font-size: 16px;
          color: $lightlightGrey;
        }
      }
    }

    .contacts__wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      white-space: pre-line;
      margin-top: 16px;
      margin-left: 10px;
      font-size: 16px;
      line-height: 24px;

      p {
        margin-bottom: 46px;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .contact_page__wrapper {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 50px;
  }

  .contact_page__wrapper .contact_page__headline {
    font-size: 42px;
  }

  .contact_page__wrapper .contact_page__inner_wrapper {
    flex-direction: column;
  }

  .contact_page__wrapper .contact_page__inner_wrapper .contacts__wrapper {
    margin-top: 20px;
    font-size: 14px;
  }

  .contact_page__wrapper .contact_page__inner_wrapper .contacts__wrapper p {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}
