.position__wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;

  .position {
    font-weight: 500;
    margin-right: 95px;
    margin-top: 7px;
    width: 190px;
    text-align: left;
  }

  .position_name__wrapper {
    display: flex;
    border-bottom: 1px solid $greyBorderDarker;
    align-items: center;

    .position_name__text {
      width: 390px;
      font-size: 36px;
      font-family: 'TT Firs Neue';
      text-align: left;
    }

    .text__wrapper {
      display: flex;
      border-bottom: 1px solid $greyBorderDarker;
      align-items: center;

      .text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
      }
    }

    .add-delete__sign {
      width: 40px;
      font-size: 60px;
      font-family: 'TT Firs Neue';
      margin-left: 110px;
    }

    .add-delete__sign:hover {
      cursor: pointer;
    }
  }
}

.dropdown__wrapper {
  display: flex;
  flex-direction: column;
  transition: all 1s ease;

  .section__wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;

    .label__wrapper {
      width: 190px;
      margin-right: 95px;
      align-self: flex-start;

      .label_name {
        font-weight: 500;
        text-align: left;
      }
    }

    .text__wrapper {
      width: 540px;
      padding-bottom: 40px;
      border-bottom: 1px solid $greyBorderDarker;

      .text {
        text-align: left;
      }
    }
  }

  .button__wrapper {
    margin-top: 20px;
    margin-bottom: 100px;
    margin-left: 285px;
  }
}

@media only screen and (max-width: 960px) {
  .position__wrapper .position {
    width: 20vw;
    margin-right: 0px;
  }

  .dropdown__wrapper .section__wrapper .label__wrapper {
    width: 20vw;
    margin-right: 0px;
  }

  .dropdown__wrapper .button__wrapper {
    margin-left: 20vw;
  }
}

@media only screen and (max-width: 830px) {
  .position__wrapper .position {
    width: 60px;
    margin-right: 0px;
    font-size: 14px;
  }

  .position__wrapper .position_name__wrapper .position_name__text {
    font-size: 24px;
    width: 240px;
  }

  .position__wrapper .position_name__wrapper .add-delete__sign {
    width: 30px;
    font-size: 40px;
    margin-left: 10px;
  }

  .dropdown__wrapper .section__wrapper .text__wrapper {
    width: 280px;
    font-size: 14px;
  }

  .dropdown__wrapper .section__wrapper .label__wrapper {
    width: 60px;
  }

  .dropdown__wrapper .section__wrapper .label__wrapper .label_name {
    font-size: 14px;
  }

  .dropdown__wrapper .button__wrapper {
    margin-top: 10px;
    margin-left: 120px;
    margin-bottom: 20px;
  }
}
