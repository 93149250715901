.template_wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;

  .give_us_a_call__wrapper {
    position: fixed;
    top: 68%;
    right: 0%;
    width: 120px;
    height: 130px;
    background-color: $lightBrown;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .call_text {
      margin-top: 10px;
      margin-bottom: 12.75px;
      font-size: 16px;
      line-height: 20.8px;
      color: $white;
    }
    button {
      border: none;
      padding: 0;
      background-color: $transparent;

      .headphone__img {
        height: 42.5px;
        width: 42.5px;
      }
    }

    button:hover {
      cursor: pointer;
    }

    button:active {
      transform: scale(0.98);
    }
  }

  .content__wrapper {
    display: flex;
    flex: 1;
  }
}

@media only screen and (max-width: 1125px) {
  .template_wrapper .give_us_a_call__wrapper {
    width: 90px;
    height: 97.5px;
  }

  .template_wrapper .give_us_a_call__wrapper .call_text {
    font-size: 13px;
    margin-bottom: 8px;
  }

  .template_wrapper .give_us_a_call__wrapper button .headphone__img {
    height: 30px;
    width: 30px;
  }
}

// @media only screen and (max-width: 1125px) {
//   .template_wrapper .give_us_a_call__wrapper {
//     height: 90px;
//     width: 108.3px;
//   }
// }
