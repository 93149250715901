.howitworks__wrapper {
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
  flex: 1;

  .howitworks__headline {
    font-family: 'TT Firs Neue';
    font-size: 82px;
  }

  .howitworks__inner_wrapper {
    flex: 1;
    margin-top: 80px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .howitworks__text_wrapper {
      display: flex;
      align-items: center;
      margin-right: 7.6vw;

      .howitworks__text {
        font-size: 18px;
        text-align: left;
        line-height: 30px;
      }
    }
  }

  .howitworks__semi_headline {
    margin-top: 120px;
    font-family: 'TT Firs Neue';
    font-size: 35px;
  }

  .howitworks__images_wrapper {
    margin-top: 100px;
    height: 540px;
    display: flex;
    justify-content: space-between;

    .mobile_app__wrapper {
      height: 100%;
      width: 25vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      .mobile_app__inner_wrapper {
        height: 100%;
        display: flex;

        .mobileApp_img1 {
          height: 400px;
          width: auto;
        }

        .mobileApp_img2 {
          height: 400px;
          width: auto;
          align-self: flex-end;
        }
      }
    }

    .web_app__wrapper {
      display: flex;
      flex-direction: column;
      width: 25vw;

      .webApp__img {
        width: 100%;
        height: auto;
      }
    }

    .call_center_app__wrapper {
      width: 25vw;

      .concerge__img {
        width: 100%;
        height: auto;
      }
    }

    .image_headline {
      font-family: 'TT Firs Neue';
      font-size: 20px;
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 1465px) {
  .howitworks__wrapper
    .howitworks__inner_wrapper
    .howitworks__text_wrapper
    .howitworks__text {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 1072px) {
  .howitworks__wrapper .howitworks__images_wrapper {
    display: none;
  }
  .howitworks__wrapper .howitworks__headline {
    font-size: 62px;
  }

  .howitworks__wrapper .howitworks__inner_wrapper {
    height: 340px;
  }

  .howitworks__wrapper
    .howitworks__inner_wrapper
    .howitworks__text_wrapper
    .howitworks__text {
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 956px) {
  .howitworks__wrapper .howitworks__inner_wrapper img {
    display: none;
  }

  .howitworks__wrapper .howitworks__headline {
    font-size: 42px;
  }
}
