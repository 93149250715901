.overview {
  white-space: pre-line;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .why-275__headline {
    font-family: 'TT Firs Neue';
    font-size: 82px;
    margin-top: 80px;
  }

  .overview__weapper {
    margin-top: 80px;
    display: flex;
    height: 440px;
    align-items: center;
    flex: 1;

    .overview_mobilePhone__img {
      margin-right: 7.6vw;
      height: 440px;
    }

    .arrow_btn {
      height: 20px;
      background-color: $transparent;
      border: none;
      margin-bottom: 50px;

      .arrow_img {
        height: 17px;
        width: auto;
      }
    }

    .arrow_btn:active {
      .arrow_img {
        transform: scale(0.9);
      }
    }

    .overview_texts_dots__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .overview_texts__wrapper {
        margin-left: 20px;
        margin-right: 20px;
        width: 40vw;
        overflow-x: scroll;
        display: flex;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .overview_text__wrapper {
          display: flex;
          width: 40vw;
          align-items: center;

          .overview__text {
            margin-top: 20px;
            margin-left: 10px;
            margin-right: 10px;
            text-align: left;
            font-size: 16px;
            width: 40vw;
            font-weight: 500;
            line-height: 30px;
          }
        }
      }

      .dots__wrapper {
        display: flex;
        margin-top: 50px;

        .dot_black {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $black;
          margin-left: 10px;
        }

        .dot_grey {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $grey;
          margin-left: 10px;
        }
      }
    }

    .overview_texts__wrapper::-webkit-scrollbar {
      display: none;
    }
  }
}

@media only screen and (max-width: 1072px) {
  .overview .why-275__headline {
    font-size: 62px;
  }

  .overview .overview__weapper {
    height: 340px;
  }

  .overview .overview__weapper .overview_mobilePhone__img {
    margin-right: 5vw;
    height: 340px;
  }

  .overview .overview__weapper .arrow_btn {
    display: none;
  }

  .overview
    .overview__weapper
    .overview_texts_dots__wrapper
    .overview_texts__wrapper {
    margin-right: 10px;
    margin-left: 10px;
    width: 40vw;
  }

  .overview
    .overview__weapper
    .overview_texts_dots__wrapper
    .overview_texts__wrapper
    .overview_text__wrapper
    .overview__text {
    font-size: 14px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 956px) {
  .overview .why-275__headline {
    font-size: 42px;
  }

  .overview .overview__weapper {
    margin-top: 40px;
  }

  .overview .overview__weapper .overview_mobilePhone__img {
    display: none;
  }

  .overview
    .overview__weapper
    .overview_texts_dots__wrapper
    .overview_texts__wrapper {
    overflow-x: hidden;
    flex-direction: column;
    width: 100%;
  }
  .overview .overview__weapper .overview_texts_dots__wrapper .dots__wrapper {
    display: none;
  }

  .overview .overview__weapper .overview_texts_dots__wrapper {
    width: 100%;
    flex: 1;
  }

  .overview
    .overview__weapper
    .overview_texts_dots__wrapper
    .overview_texts__wrapper {
    flex: 1;
  }

  .overview
    .overview__weapper
    .overview_texts_dots__wrapper
    .overview_texts__wrapper
    .overview_text__wrapper
    .overview__text {
    display: flex;
    flex: 1;
    font-size: 14px;
    line-height: 26px;
    width: 100%;
  }

  .overview .overview__weapper {
    height: auto;
  }
  .overview
    .overview__weapper
    .overview_texts_dots__wrapper
    .overview_texts__wrapper
    .overview_text__wrapper {
    display: flex;
    flex: 1;
    width: 100%;
  }
}
