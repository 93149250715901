.subscription__authcard__wrapper {
  background-color: white;
  border: none;
  height: 528px;
  width: 350px;
  margin-bottom: 150px;

  display: flex;

  .subscription__card__inner__wrapper {
    display: flex;
    flex-direction: column;
    margin: 24px 24px 16px 24px;

    .subscription__type__text {
      text-align: right;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 500;
    }

    .subscription__price__wrapper {
      margin-top: 35px;
      padding-bottom: 10px;
      display: flex;
      border-bottom-style: solid;
      border-width: 1px;
      border-color: $borderColor;

      .subscription__price__text {
        font-size: 56px;
        font-family: 'TT Firs Neue';
        text-align: left;
      }

      .subscription__price__period {
        margin-left: 10px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.Basic {
  background-image: url("../../../assets/images/basic.png");
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 24px;
  display: flex;
}

.Plus {
  background-image: url("../../../assets/images/plus.png");
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 24px;
  display: flex;
}

.Premium {
  background-image: url("../../../assets/images/premium.png");
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 24px;
  display: flex;
}

@media only screen and (max-width: 1193px) {
  .subscription__authcard__wrapper {
    height: 440.4px;
    width: 280px;
  }

  .subscription__authcard__wrapper
    .subscription__card__inner__wrapper
    .subscription__price__wrapper {
    padding-bottom: 5px;
  }

  .subscription__authcard__wrapper
    .subscription__card__inner__wrapper
    .subscription__price__wrapper
    .subscription__price__text {
    font-size: 45px;
  }

  .subscription__authcard__wrapper
    .subscription__card__inner__wrapper
    .subscription__price__wrapper
    .subscription__price__period {
    margin-top: 22px;
  }
}

@media only screen and (max-width: 866px) {
  .subscription__authcard__wrapper {
    min-width: 280px;
  }

  .subscription__authcard__wrapper
    .subscription__card__inner__wrapper
    .subscription__price__wrapper {
  }

  .subscription__authcard__wrapper
    .subscription__card__inner__wrapper
    .subscription__price__wrapper
    .subscription__price__text {
    font-size: 30px;
  }

  .subscription__authcard__wrapper
    .subscription__card__inner__wrapper
    .subscription__price__wrapper
    .subscription__price__period {
    margin-top: 8px;
  }
}
