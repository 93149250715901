.security__wrapper {
  display: flex;
  height: 555px;

  white-space: pre-line;
  align-items: center;
  margin-bottom: 150px;
  flex: 1;

  .security__text_wrapper {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .headline__text {
      font-size: 40px;
      font-family: $headlineFont;
    }

    .text__wrapper {
      padding-right: 110px;

      .security__text {
        margin-top: 60px;
        text-align: left;
        line-height: 24px;
        font-size: 16px;
      }
    }
  }

  .security__img {
    margin-top: 100px;
    width: auto;
    height: 326px;
  }
}

@media only screen and (max-width: 1277px) {
  .security__wrapper .security__img {
    height: 250px;
  }
}

@media only screen and (max-width: 1072px) {
  .security__wrapper .security__text_wrapper .text__wrapper {
    padding-right: 70px;
  }

  .security__wrapper .security__text_wrapper .text__wrapper .security__text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 956px) {
  .security__wrapper {
    flex-direction: column;
  }

  .security__wrapper .security__text_wrapper .text__wrapper {
    padding-right: 0px;
  }

  .security__wrapper .security__text_wrapper {
    width: 100%;
  }
}
