.news-item {
  margin-top: 80px;
  padding: 5%;
}

.news-item__headline {
  font: 'Roboto';
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  text-align: left;
  margin-top: 20px;

}

.news-item__summary {
  font: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
}

.news-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.news-item__readmore {
  font: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
}

.news-item__readmore > a {
  color: blue; text-decoration: none;
  text-decoration: none;
}

.news-item__readmore > a :visited {
  color: blue; text-decoration: none;
  text-decoration: none;
}