.careers_page__wrapper {
  margin-top: 100px;
  margin-left: 11.5vw;
  margin-right: 31.25vw;
  margin-bottom: 150px;
  white-space: pre-line;

  .careers__headline {
    margin-left: 60px;
    font-size: 82px;
    font-family: 'TT Firs Neue';
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 830px) {
  .careers_page__wrapper {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .careers_page__wrapper .careers__headline {
    font-size: 42px;
    margin-left: 60px;
  }
}
