.sales_agent_program_page__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 80px;

  .agent_program__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 25vw;
    margin-right: 25vw;
    

    .sales_agent_program_page__headline {
      font-family: $headlineFont;
      font-size: 56px;
      margin-bottom: 40px;
    }

    .sales_agent__text {
      font-size: 18px;
      text-align: left;
    }

    .sales_agent_img__wrapper {
      margin-top: 50px;
    }
  }

  .sales_agent_img {
    width: 75%;
  }

  .apply__text {
    font-size: 56px;
    font-family: $headlineFont;
    margin-top: 60px;
  }

  .form__wrapper {
    margin-top: 80px;
    margin-bottom: 100px;
    margin-left: 31.25vw;
    margin-right: 31.25vw;
  }
}

@media only screen and (max-width: 1271px) {
  .sales_agent_program_page__wrapper .form__wrapper {
    margin-left: 25vw;
    margin-right: 25vw;
  }
}

@media only screen and (max-width: 870px) {
  .sales_agent_program_page__wrapper
    .agent_program__wrapper
    .sales_agent__text {
    font-size: 16px;
    line-height: 20px;
  }

  .sales_agent_program_page__wrapper
    .agent_program__wrapper
    .sales_agent_program_page__headline {
    font-size: 42px;
  }

  .sales_agent_program_page__wrapper
    .agent_program__wrapper
    .sales_agent__text
    .new_line_wide_page {
    display: none;
  }

  .sales_agent_program_page__wrapper .apply__text {
    font-size: 42px;
  }

  .sales_agent_program_page__wrapper .form__wrapper {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}
