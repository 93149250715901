@font-face {
  font-family: 'TT Firs Neue';
  src: url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.eot');
  src: local('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.woff'),
    local('TTFirsNeue-DemiBold'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.woff2') format('woff2'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.woff') format('woff'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
  src: local('./assets/fonts/Roboto/Roboto-Regular.ttf'),
    /* local('TTFirsNeue-DemiBold'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.woff2') format('woff2'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.woff') format('woff'), */
      url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: bold;
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
  src: local('./assets/fonts/Roboto/Roboto-Regular.ttf'),
    /* local('TTFirsNeue-DemiBold'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.woff2') format('woff2'),
    url('./assets/fonts/TTFirstNeue/TTFirsNeue-Medium.woff') format('woff'), */
      url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
  font-family: 'Roboto';
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
