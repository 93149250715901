.pagination {
  clear:both;
  float:left;
  margin-top:1em;
  padding-bottom:1em;
  width:100%;
  background-color: white;
  background-repeat:no-repeat;
}

ul.pagination {
  display: table;
  margin-left: auto;
  margin-right: 0em;
}

.pagination li
{
  display: inline-block;
  border: 2px solid black;
  margin-right:30px;
  background-color: white;
  color: white;
}
.pagination li a:hover
{
  text-decoration: none;
  color: white;
}
.pagination li:hover
{
  border: 2px solid #FFFFFF;
  background-color: black;
  color: white;
}
.pagination li a
{
  color:black;
  font-size: 1em;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: 0.7em 1em 0.7em 1em;
}
.pagination li.active a
{
  color:#FFFFFF;
  text-decoration: none;
}
.pagination li.active
{
  border: 2px solid #FFFFFF;
  background-color: black;
}

.news-items__wrapper {
  display: flex;
  flex: 1;
}

.news-items {
	display: flex;
	flex-wrap: wrap;
}

.news-items > div {
  flex-grow: 0;
	width: 33.33%;
}

@media only screen and (max-width: 830px) {
  .news-items > div {
    width: 100%;
  }
}

.news__wrapper {
  .news__inner_wrapper {
    margin-left: 11.5vw;
    margin-right: 11.5vw;
  }
}

.news__headline {
  font-family: 'TT Firs Neue';
  font-size: 82px;
  margin-top: 80px;
}

@media only screen and (max-width: 956px) {
  .news__wrapper .news__inner_wrapper {
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .news__headline {
    font-size: 42px;
  }
}
