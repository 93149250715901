.component__wrapper {
  border-bottom: 1px solid $greyBorderDarker;
  display: flex;
  flex-direction: column;

  button {
    border: none;
    background-color: $transparent;
    padding: 0;

    .question__wrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;

      .question__text {
        margin-right: 20px;
        font-family: 'Roboto-bold';
        font-size: 18px;
        text-align: left;
        line-height: 28px;
      }

      .arrow_img {
        width: 14px;
        height: 22px;
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
      }

      .arrow_img_rotated {
        width: 14px;
        height: 22px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
      }

      .diagonal_img {
        width: 18px;
        height: 18px;
      }
    }
  }

  button:hover {
    cursor: pointer;
  }

  .my-node-enter {
    max-height: 0px;

    overflow: hidden;
  }
  .my-node-enter-active {
    height: auto;
    -webkit-transition: max-height 0.4s ease-in;
    transition: max-height 0.4s ease-in;
    max-height: 160px;
  }
  .my-node-exit {
    max-height: 160px;
  }
  .my-node-exit-active {
    overflow: hidden;
    -webkit-transition: max-height 2s ease-out;
    transition: max-height 2s ease-out;
    max-height: 0px;
  }

  .answer__text {
    margin-top: -2px;
    margin-bottom: 24px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    line-height: 20px;
    display: flex;
    transition: 0.3s ease;
  }

  .answer__text:active {
    display: flex;
  }
}
