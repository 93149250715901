.home__wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .home__inner_wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 11.5vw;
    margin-right: 11.5vw;
  }
}

@media only screen and (max-width: 956px) {
  .home__wrapper .home__inner_wrapper {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}

.appstore_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 5vw;
}