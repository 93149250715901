.appfooter_wrapper {
  width: 100vw;
  height: 360px;
  display: flex;
  flex-direction: column;
  border-top: solid 1px $borderGrey;

  .content_wrapper {
    margin-left: 11.5vw;
    margin-right: 11.5vw;
    height: 301px;
    display: flex;

    .newsletter_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .logo_text {
        margin-top: 42px;
        font-size: 26px;

        font-family: 'TT Firs Neue';
      }

      .newsletter_text {
        margin-top: 28px;
        font-size: 14px;
        color: $lightGrey;
        text-align: left;
      }

      .mail_form__container {
        margin-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $inputGrey;
        width: 285px;
        border-radius: 14px;

        .email-input {
          background-color: transparent;
          border: none;
          height: 48px;

          padding-left: 14px;
          flex: 1;
          font-size: 16px;
        }

        button {
          background-color: transparent;
          width: 50px;
          border: none;

          img {
            height: 11px;
            width: 13px;
          }
        }

        .email-input::placeholder {
          font-size: 16px;
        }

        .email-input:focus {
          outline: none;
        }

        button:active {
          img {
            transform: scale(0.8);
          }
        }
      }
    }

    .links_wrapper {
      width: 25vw;
      margin: 78px 12vw 60px 5vw;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
      // background-color: brown;

      .link_text {
        font-family: 'Roboto-bold';
        font-size: 16px;
        color: $black;
        margin-bottom: 20px;
        font-weight: 500;
        margin-right: 66px;
      }
    }

    .social_wrapper {
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        border: none;
        background-color: transparent;

        .facebook_img {
          width: 91px;
          height: 32px;
        }

        .instagram_img {
          width: 91px;
          height: 32px;
        }
        .twitter_img {
          width: 71px;
          height: 32px;
        }
      }

      a:active {
        transform: scale(0.98);
      }
    }
  }

  .rights_wrapper {
    margin-left: 11.5vw;
    margin-right: 11.5vw;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p > a {
      font-size: 14px;
      color: $lightGrey;
    }

    .right_text {
      color: $darkGrey;
    }

    .policy_terms_wrapper {
      display: flex;
      align-items: center;

      p:first-child {
        margin-right: 25px;
      }
    }
  }
}

@media only screen and (max-width: 1349px) {
  .appfooter_wrapper .content_wrapper .links_wrapper .link_text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1223px) {
  .appfooter_wrapper .content_wrapper .links_wrapper .link_text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1051px) {
  .appfooter_wrapper .content_wrapper .social_wrapper a {
    margin-left: 20vw;
  }

  .appfooter_wrapper .content_wrapper .links_wrapper {
    display: none;
  }
}

@media only screen and (max-width: 752px) {
  .appfooter_wrapper .content_wrapper .social_wrapper a {
    margin-left: 10vw;
  }

  .appfooter_wrapper .content_wrapper .links_wrapper {
    display: none;
  }
}

@media only screen and (max-width: 658px) {
  .appfooter_wrapper .content_wrapper {
    flex-direction: column;
    width: 285px;
  }

  .appfooter_wrapper .content_wrapper .social_wrapper {
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;

    a {
      margin-left: 0px;

      .facebook_img {
        width: 80px;
        height: auto;
      }

      .instagram_img {
        width: 80px;
        height: auto;
      }
      .twitter_img {
        width: 60px;
        height: auto;
      }
    }
  }

  .appfooter_wrapper .content_wrapper .links_wrapper {
    display: none;
  }

  .appfooter_wrapper .rights_wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}
