.compliance__wrapper {
  display: flex;
  flex: 1;
  margin-bottom: 150px;

  .compliance__img {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-right: 100px;
    
    height: 226px;
  }
  
  .complience_text__wrapper {
    width: 50%;

    .complience__headline {
      margin-bottom: 40px;
      font-size: 40px;
      font-family: $headlineFont;
      text-align: left;
    }

    .complience__text {
      text-align: left;
      margin-top: 20px;
      line-height: 24px;
      font-size: 16px;
    }

    .onetrust__text {
      text-align: left;
      margin-top: 20px;
      line-height: 24px;
      font-size: 18px;
    }

    .list__wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 15px;

      li {
        margin-bottom: 7px;
        padding-left: 15px;
      }
    }
  }
}

@media only screen and (max-width: 1277px) {
  .compliance__wrapper .compliance__img {
    width: auto;
    height: 175px;
  }
}

@media only screen and (max-width: 1072px) {
  .compliance__wrapper .complience_text__wrapper .complience__text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 956px) {
  .compliance__wrapper {
    flex-direction: column;
  }

  .compliance__wrapper .compliance__img {
    width: 100%;
    height: auto;
  }

  .compliance__wrapper .complience_text__wrapper {
    width: 100%;
  }
}
