.about_company_page__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 100px;
  margin-bottom: 140px;

  .about_company__headline {
    margin-bottom: 80px;
    font-family: $headlineFont;
    font-size: 82px;
  }

  .content__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 31.25vw;
    margin-right: 31.25vw;

    .info__text {
      text-align: left;
      line-height: 24px;
    }

    .hand_touching__img {
      width: 100%;
      height: auto;
      margin-top: 80px;
    }
  }
}

@media only screen and (max-width: 1273px) {
  .about_company_page__wrapper .about_company__headline {
    font-size: 72px;
  }

  .about_company_page__wrapper .content__wrapper {
    margin-left: 25vw;
    margin-right: 25vw;
  }
}

@media only screen and (max-width: 830px) {
  .about_company_page__wrapper .about_company__headline {
    font-size: 42px;
  }

  .about_company_page__wrapper .content__wrapper {
    margin-left: 7vw;
    margin-right: 7vw;
  }
}
