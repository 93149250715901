.terms__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 100px;
  margin-bottom: 140px;

  .terms__headline {
    margin-bottom: 80px;
    font-family: $headlineFont;
    font-size: 82px;
  }

  .content__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 20vw;
    margin-right: 20vw;

    .terms__text {
      text-align: left;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: 830px) {
  .terms__wrapper .terms__headline {
    font-size: 42px;
  }

  .terms__wrapper .content__wrapper {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}