.black_button {
  width: 100%;
  background-color: $black;
  color: $white;
  height: 48px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  letter-spacing: 0.1px;
}

.black_button:active {
  transform: scale(0.98);
}
