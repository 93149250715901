.why-275__wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .why-725__inner_wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 11.5vw;
    margin-right: 11.5vw;
  }
}

@media only screen and (max-width: 956px) {
  .why-275__wrapper .why-725__inner_wrapper {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}
