.TroubleshootingPage__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100vw;
  margin-bottom: 100px;

  .TroubleshootingPage_inner__wrapper {
    margin: 60px 31.25vw 100px 31.25vw;
    display: flex;
    flex-direction: column;
    flex: 1;

    .TroubleshootingPage__headline {
      font-size: 40px;
      font-family: 'TT Firs Neue';
      text-align: left;
      margin-bottom: 80px;
    }
  }

  .appstore_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .TroubleshootingPage__wrapper .TroubleshootingPage_inner__wrapper {
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 830px) {
  .TroubleshootingPage__wrapper {
    margin-bottom: 70px;
  }

  .TroubleshootingPage__wrapper .TroubleshootingPage_inner__wrapper {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 60px;
  }

  .TroubleshootingPage__wrapper
    .TroubleshootingPage_inner__wrapper
    .TroubleshootingPage__headline {
    font-size: 30px;
  }
}
