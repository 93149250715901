.single-news-item__wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 100px;
  margin-bottom: 140px;
  margin-left: 2vw;
  margin-right: 2vw;

  .single-news-item__headline {
    margin-bottom: 80px;
    font-family: $headlineFont;
    font-size: 82px;
  }

  .left-arrow__wrapper {
    margin-top: 20%;
    width: 30%;
  }

  .right-arrow__wrapper {
    margin-top: 20%;
    width: 30%;
  }

  .left-arrow__icon {
    width: 100px;
    height: 100px;
  }

  .right-arrow__icon {
    width: 100px;
    height: 100px;
  }

  .hidden-arrow {
    visibility: hidden;
  }

  .content__wrapper {
    display: flex;
    flex-direction: column;

    .main__text {
      text-align: left;
      line-height: 24px;
      margin-top: 80px;
      width: 100%;
    }

    .main__img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: 1273px) {
  .single-news-item__wrapper .single-news-item__headline {
    font-size: 72px;
  }

  .single-news-item__wrapper .content__wrapper {
    margin-left: 20vw;
    margin-right: 20vw;
  }
}

@media only screen and (max-width: 830px) {
  .single-news-item__wrapper {
    .content__wrapper {
      margin-left: 0vw;
      margin-right: 0vw;
    }

    .single-news-item__headline {
      font-size: 42px;
    }
    
    .left-arrow__icon {
      visibility: hidden;
    }
  
    .right-arrow__icon {
      visibility: hidden;
    }
  
    .left-arrow__wrapper {
      width: 0%;
    }
  
    .right-arrow__wrapper {
      width: 0%;
    }
  }
}