.appheader__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 60px;
  width: 100vw;
  background-color: $white;
  display: flex;
  border-bottom: solid 1px $borderGrey;

  .appheader_inner_wrapper {
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;

    .dropdown_links__wrapper {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: $white;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

      .hambeurger_link {
        font-family: 'Roboto';
        text-align: center;
        padding: 20px 40px;
        margin-left: 10px;
        margin-right: 10px;
        border-top: 1px solid #d9d9d9;

        .hamburger_link__text {
          color: $black;
          width: 100%;
        }
      }
    }

    .hamburger__wrapper {
      display: none;
      margin-right: 10px;
    }

    .logo_text {
      color: $black;
      font-size: 30px;
      margin-right: 13.7vw;
      font-family: 'TT Firs Neue';
    }

    .links__wrapper {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      .dropdown {
        position: relative;
        height: 100%;

        .link_text {
          font-size: 16px;
          color: $black;
          display: flex;
          align-items: center;
        }

        .link_text_underline {
          font-size: 16px;
          color: $black;
          text-decoration: underline;
          display: flex;
          align-items: center;
        }

        .link_contact {
          font-size: 16px;
          width: 140px;
          height: 36px;
          background-color: $black;
          color: $white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
          display: flex;
          align-items: center;
        }

        .arrow_img {
          height: 5px;
          width: 10px;
          margin-left: 6px;
          margin-top: 3px;
          transition: 0.4s;
          -webkit-transition: 0.4s;
          -moz-transition: 0.4s;
        }

        .dropdown_content {
          display: none;
          position: absolute;
          width: 150px;
          background-color: $white;
          padding-top: 12px;

          .separator {
            height: 10px;
            width: 20px;
          }

          .dropdown__wrapper {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

            .dropdown_text {
              padding-top: 10px;
              padding-bottom: 10px;
              margin-left: 10px;
              margin-right: 10px;
              font-size: 16px;
              color: $black;
              border-bottom: 1px solid #d9d9d9;
            }
          }
        }
      }

      .dropdown:hover .dropdown_content {
        display: flex;
        flex-direction: column;
        text-align: left;
      }

      .dropdown:hover .arrow_img {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
      }
    }
  }
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 1273px) {
  .appheader__wrapper .appheader_inner_wrapper .logo_text {
    margin-right: 40px;
  }

  .appheader__wrapper
    .appheader_inner_wrapper
    .links__wrapper
    .dropdown
    .link_text {
    font-size: 14px;
  }

  .appheader__wrapper
    .appheader_inner_wrapper
    .links__wrapper
    .dropdown
    .link_text_underline {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .appheader__wrapper .appheader_inner_wrapper {
    justify-content: space-between;
  }
  .appheader__wrapper .appheader_inner_wrapper .links__wrapper {
    display: none;
  }

  .appheader__wrapper .appheader_inner_wrapper .hamburger__wrapper {
    display: flex;
  }
}
