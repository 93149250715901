.hamburger_dropdown__wrapper {
  .headlin_arrow_wrapper {
    justify-content: center;
    align-items: center;
    display: flex;

    .arrowImg {
      margin-left: 6px;
      width: 10px;
      height: 5px;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
    }

    .arrowImgRotated {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
      margin-left: 6px;
      width: 10px;
      height: 5px;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
    }
  }

  .hamburger_drowpdown__items {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .dropdown_text {
      color: $black;
      margin-bottom: 15px;
    }
  }
}
