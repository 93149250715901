.frequency__picker__wrapper {
  display: flex;

  p:hover {
    cursor: pointer;
  }

  .clicked__picker {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    border-bottom-style: solid;
    padding-bottom: 10px;
    width: 90px;
    border-width: 2px;

    .clicked__text {
      font-size: 16px;
      font-weight: 600;
      align-self: center;
    }
  }

  .divider {
    width: 10px;
  }

  .not__clicked__picker {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    border-bottom-style: solid;
    padding-bottom: 10px;
    width: 90px;
    border-width: 1px;
    border-color: #c0baba;

    .not__clicked__text {
      font-size: 16px;
      color: #c0baba;
      font-weight: 600;
      align-self: center;
    }
  }
}
