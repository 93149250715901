.companyNews__wrapper {
  display: flex;

  white-space: pre-line;
  align-items: center;
  margin-bottom: 150px;
  margin-top: 10vh;
  flex: 1;

  .companyNews__text_wrapper {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10vh;
    margin-left: 10vh;

    .headline__text {
      font-size: 56px;
      font-family: $headlineFont;
      line-height: 60px;
      text-align: center;
    }

    .text__wrapper {
      padding-right: 110px;

      .companyNews__text {
        margin-top: 60px;
        text-align: center;
        line-height: 28px;
        font-size: 18px;
      }

      a {
        color: blue;
        font-weight: normal;
      }

      a:visited {
        color: blue;
        font-weight: normal;
      }
    }
  }

  .companyNews__img {
    width: 40vw;
    height: auto;
  }
}

@media only screen and (max-width: 1277px) {
  .companyNews__wrapper .companyNews__img {
    margin-top: 10vh;
  }

  .companyNews__wrapper .companyNews__text_wrapper {
    width: 100%;
  }

  .companyNews__img {
    width: 40vw;
  }
}

@media only screen and (max-width: 1072px) {
  .companyNews__wrapper .companyNews__text_wrapper .text__wrapper {
    padding-right: 70px;
  }

  .companyNews__wrapper .companyNews__text_wrapper .text__wrapper .companyNews__text {
    font-size: 14px;
  }

  .companyNews__img {
    width: 40vw;
  }
}

@media only screen and (max-width: 956px) {
  .companyNews__wrapper {
    flex-direction: column;
  }

  .companyNews__wrapper .companyNews__text_wrapper .text__wrapper {
    padding-right: 0px;
  }

  .companyNews__img {
    width: 100vw !important;
  }
}
