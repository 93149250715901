.AppStore__wrapper {
  display: flex;
  flex-direction: column;
  width: 580px;

  .AppStore__headline {
    font-size: 82px;
    font-family: 'TT Firs Neue';
    margin-bottom: 50px;
    text-align: left;
  }

  .store_images__wrapper {
    display: flex;
    justify-content: space-between;

    button {
      padding: 0;
      background-color: $transparent;
      border: none;

      .store__img {
        width: 280px;
        height: auto;
      }
    }

    button:active {
      transform: scale(0.98);
    }
  }
}

@media only screen and (max-width: 830px) {
  .AppStore__wrapper {
    width: 100%;
    margin-left: 20vw;
  }
  .AppStore__wrapper .AppStore__headline {
    font-size: 42px;
    font-family: 'TT Firs Neue';
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    text-align: left;
  }

  .AppStore__wrapper .store_images__wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .AppStore__wrapper .store_images__wrapper button .store__img {
    width: 200px;
    height: auto;
  }
}
