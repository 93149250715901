.hero__wrapper {
  width: 100vw;
  z-index: -1;
}

.hero  {
  position: relative;
  width: 100%;
}

.title {
  font-family: 'TT Firs Neue';
  font-size: 82px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}