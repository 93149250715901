.FAQPage__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-bottom: 100px;
  width: 100vw;

  .FAQ__wrapper {
    margin: 60px 31.25vw 150px 31.25vw;
    display: flex;
    flex-direction: column;
    flex: 1;

    .FAQ__headline {
      font-size: 40px;
      font-family: 'TT Firs Neue';
      text-align: left;
    }

    .FAQ__about {
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 16px;
      text-align: left;
      line-height: 24px;
    }
  }

  .appstore_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .FAQPage__wrapper .FAQ__wrapper {
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 830px) {
  .FAQPage__wrapper {
    margin-bottom: 70px;
  }

  .FAQPage__wrapper .FAQ__wrapper {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 60px;
  }

  .FAQPage__wrapper .FAQ__wrapper .FAQ__headline {
    font-size: 30px;
  }
}
