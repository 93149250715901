.form__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  label {
    text-align: left;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 400;
    margin-bottom: 6px;
  }

  input {
    height: 48px;
    border-radius: 14px;
    border: none;
    background-color: $borderGrey;
    padding-left: 16px;
    margin-bottom: 18px;
    font-size: 16px;
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    font-size: 16px;
    color: $lightlightGrey;
  }
}
