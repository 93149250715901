$white: #ffffff;
$black: #000000;

$borderGrey: #eeeeee;
$lightGrey: #9b9b9b;
$grey: #b3afaf;
$inputGrey: #f5f3f5;
$darkGrey: rgba(79, 79, 79, 1);
$lightlightGrey: #acacac;
$greyBorderDarker: #cdcdcd;
$borderColor: #e2e2e2;

$transparent: rgba(255, 255, 255, 0);

$headlineFont: 'TT Firs Neue';

$lightBrown: #4f4f4f;
