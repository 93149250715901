.agent_application_page__wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 80px;
  margin-left: 31.25vw;
  margin-right: 31.25vw;
  width: 100vw;

  .agent_application_page__headline {
    font-size: 56px;
    font-family: $headlineFont;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1271px) {
  .agent_application_page__wrapper {
    margin-left: 25.25vw;
    margin-right: 25.25vw;

    .agent_application_page__headline {
      font-size: 48px;
    }
  }
}

@media only screen and (max-width: 870px) {
  .agent_application_page__wrapper {
    margin-left: 15vw;
    margin-right: 15vw;

    .agent_application_page__headline {
      font-size: 42px;
    }
  }
}
