.subscription_plans__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-left: 11.5vw;
  margin-right: 11.5vw;
  margin-top: 80px;
  width: 100vw;

  .subscription_plans__headline {
    font-family: 'TT Firs Neue';
    font-size: 82px;
  }

  .subscription_plans__text {
    text-align: left;
    margin-top: 60px;
    font-size: 21px;
    line-height: 31.5px;
  }

  .subscriptions__wrapper {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;

    .subscriptioncard__wrapper {
      margin-top: 65px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 1193px) {
  .subscription_plans__wrapper {
    margin-left: 4vw;
    margin-right: 4vw;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 993px) {
  .subscription_plans__wrapper .subscription_plans__headline {
    font-size: 62px;
  }

  .subscription_plans__wrapper .subscription_plans__text {
    font-size: 16px;
  }

  .subscription_plans__wrapper {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}

@media only screen and (max-width: 866px) {
  .subscription_plans__wrapper .subscription_plans__headline {
    font-size: 42px;
  }

  .subscription_plans__wrapper .subscription_plans__text {
    font-size: 14px;
    line-height: 25.5px;
    margin-left: 4vw;
    margin-right: 4vw;
  }

  .subscription_plans__wrapper .subscriptions__wrapper {
    margin-top: 75px;
  }

  .subscription_plans__wrapper
    .subscriptions__wrapper
    .subscriptioncard__wrapper {
    overflow-x: scroll;
    height: 500px;
    flex: 1;
    width: 100%;
  }
}
